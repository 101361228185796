import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Heading,
  Image,
  Link,
  Text,
  useColorMode,
} from '@chakra-ui/react';
import { calvin, maria, stephanie } from '@src/assets/avatars';
import BrainShiny from '@src/assets/brainShiny.svg?react';
import goalImage from '@src/assets/goal.webp';
import improvementStat from '@src/assets/improvementStat.svg';
import journalsDashboardImage from '@src/assets/journalsDashboard.webp';
import starsGradient from '@src/assets/starsGradient.svg';
import AppStoreLinks from '@src/components/AppStoreLinks';
import Card from '@src/components/Card';
import NavBarNoAuth from '@src/components/NavBarNoAuth';
import QuotesCarousel from '@src/components/QuotesCarousel';
import { userQuotes } from '@src/constants';
import { useVariantStatus } from '@src/hooks';
import { ChevronLeft, Wave } from '@src/icons';
import { type FC, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Footer from './Footer';
import ImageWithText from './ImageWithText';
import PullQuote from './PullQuote';
import ValuePropCards from './ValuePropCards';
import * as images from './images';

export const landingPaddingX = { base: 'regular', md: 'mega' };
export const contentWidthStyles = { maxWidth: '72rem', mx: 'auto' };
export const heroWidthStyles = { maxWidth: '37.5rem', mx: 'auto' };
export const stackWidthStyles = { maxWidth: '25.75rem', mx: 'auto' };
export const sectionVerticalPadding = { base: '80px', lg: '160px' };
export const sectionVerticalPaddingExtra = { base: '100px', lg: '220px' };

const waveStyles = {
  width: '100%',
  height: '2rem',
  position: 'absolute',
  left: 0,
  transform: 'translate(0, -90%)',
  preserveAspectRatio: 'none',
} as const;

const Landing: FC = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const variantStatus = useVariantStatus('landing-include-depression');
  const variantStatusStat = useVariantStatus('landing-stat');
  const variant =
    typeof variantStatus.variant === 'string'
      ? variantStatus.variant
      : 'control';
  const variantStat =
    typeof variantStatusStat.variant === 'string'
      ? variantStatusStat.variant
      : 'control';

  useEffect(() => {
    if (colorMode === 'dark') {
      toggleColorMode();
    }
  }, [colorMode, toggleColorMode]);

  if (colorMode === 'dark') {
    return <div />;
  }

  return (
    <Box position="relative" data-ph-autocapture>
      <NavBarNoAuth logoType="logo" ctaType="getStarted" />

      <Box px={landingPaddingX} mt="regular">
        <Box
          display="inline"
          float={{ base: 'none', md: 'right' }}
          width={{ base: '100%', md: 'auto' }}
        >
          <Card
            borderColor="brand.2"
            display="flex"
            alignItems="center"
            borderRadius="lg"
            gap="regular"
            textAlign="left"
            as={RouterLink}
            to="/learn/ai-second-opinion"
          >
            <Box as="span" flex={1}>
              <Box textStyle="popPuffin" as="span">
                Out now!
              </Box>
              <Box
                as="span"
                display="block"
                textStyle="popGoose"
                bgClip="text"
                bgGradient="linear(to-br, var(--chakra-colors-brand-gradient-1-start), var(--chakra-colors-brand-gradient-1-end) )"
              >
                AI Second Opinion
              </Box>
            </Box>
            <Box as="span">
              <Button variant="gradientOutline" size="sm" as="span">
                Learn more
              </Button>
            </Box>
          </Card>
        </Box>
      </Box>

      {variantStatus.isLoading ? null : (
        <>
          <Box
            textAlign="center"
            flexDirection="column"
            mt={{ base: 'giga', md: 'tera' }}
            px={landingPaddingX}
            pb={{ base: 'tera', md: '10rem' }}
            layerStyle="purpleGradient"
            position="relative"
          >
            <Box maxWidth="42.75rem" mx="auto">
              <Image
                as={BrainShiny}
                alt="A brain free from anxiety (metaphorically)"
                display="inline-block"
                height="9.25rem"
                color="brand.2"
              />
              <Heading
                as="h1"
                textStyle={{ base: 'popOstrich', lg: 'popPhoenix' }}
                bgGradient="linear(to-br, blue.500, pink.600)"
                backgroundClip="text"
                mt="hecto"
                mb="regular"
              >
                {variant !== 'test' ? (
                  <>Overcome anxiety &&nbsp;depression</>
                ) : (
                  'Overcome anxiety'
                )}
              </Heading>
              <Heading
                as="h2"
                textStyle={{ base: 'swan', lg: 'heron' }}
                fontWeight="400"
                color="blue.700"
              >
                Take charge of your mental health with CBT journaling
              </Heading>
            </Box>
            <Box mt="8vh" {...stackWidthStyles}>
              <AppStoreLinks variant="gradient" showAlsoAvailableText />
            </Box>
          </Box>

          <Wave {...waveStyles} color="white" aria-hidden />

          {variantStat === 'test' ? (
            <Box py={sectionVerticalPadding} px={landingPaddingX}>
              <Box {...heroWidthStyles}>
                <Box
                  px={{ base: 'regular', md: 0 }}
                  textStyle={{ base: 'popSwan', lg: 'popHeron' }}
                  display="flex"
                  gap="deci"
                  alignItems={{ base: 'normal', md: 'center' }}
                >
                  <Box>
                    <Image
                      src={starsGradient}
                      alt=""
                      boxSize={{ base: '40px', lg: '48px' }}
                    />
                  </Box>
                  <Box
                    flex={1}
                    display="flex"
                    gap="deci"
                    flexDirection={{ base: 'column', md: 'row' }}
                    alignItems={{ base: 'normal', md: 'center' }}
                  >
                    <Box>
                      <Box
                        textStyle={{ base: 'popPhoenix', lg: 'popDragon' }}
                        bgGradient="linear(to-br, blue.500, pink.600)"
                        bgClip="text"
                        display="inline"
                      >
                        74%
                      </Box>
                    </Box>
                    <Box
                      bgGradient="linear(to-br, var(--chakra-colors-brand-gradient-3-start), var(--chakra-colors-brand-gradient-3-end))"
                      bgClip="text"
                    >
                      of people with moderate to severe anxiety see an
                      improvement within 1&nbsp;month.
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              py={sectionVerticalPadding}
              px={landingPaddingX}
              {...contentWidthStyles}
            >
              <ImageWithText
                headingText="Unstuck is proven to improve mental health"
                description="85% of users with moderate to severe anxiety or depression see an improvement within one month."
                image={{
                  src: improvementStat,
                  alt: '85%',
                  maxHeight: '14.5rem',
                  minHeight: '160px',
                }}
                isImageFirstMobile
                isImageFirst
              />
            </Box>
          )}

          <Box
            py={sectionVerticalPadding}
            px={landingPaddingX}
            {...contentWidthStyles}
          >
            <ImageWithText
              headingText="Journaling, backed by science"
              description={
                <>
                  Unstuck’s CBT journals help you put into action the principles
                  of <strong>Cognitive Behavioral Therapy</strong> (CBT), the
                  gold standard treatment for anxiety and depression.
                </>
              }
              additionalContent={
                <Link
                  as={RouterLink}
                  to="/learn/cbt"
                  color="action.secondary"
                  textStyle="popDuck"
                >
                  Learn more about CBT{' '}
                  <ChevronLeft
                    transform="rotate(180deg)"
                    boxSize="1rem"
                    aria-hidden
                  />
                </Link>
              }
              image={{
                src: journalsDashboardImage,
                alt: '',
              }}
            />
          </Box>

          <Wave {...waveStyles} color="brand.bg.10" aria-hidden />

          <Box
            bg="brand.bg.10"
            px={landingPaddingX}
            py={sectionVerticalPadding}
            textAlign="center"
          >
            <Box {...heroWidthStyles}>
              <PullQuote {...userQuotes[0]} />
            </Box>
          </Box>

          <Wave {...waveStyles} color="white" aria-hidden />

          <Box
            py={sectionVerticalPadding}
            px={landingPaddingX}
            {...contentWidthStyles}
          >
            <ImageWithText
              headingText="Face your fears"
              description={
                <>
                  <strong>Exposure therapy</strong> is one of the most powerful
                  approaches to reducing anxiety. Behind the technique is a
                  simple idea: you need to face fear to fight fear.
                </>
              }
              additionalContent={
                <Link
                  as={RouterLink}
                  to="/learn/exposure-therapy"
                  color="action.secondary"
                >
                  Learn more about exposure therapy{' '}
                  <ChevronLeft
                    transform="rotate(180deg)"
                    boxSize="1rem"
                    aria-hidden
                  />
                </Link>
              }
              image={{
                src: images.exposure,
                alt: '',
              }}
              isImageFirst
            />
          </Box>

          <Box
            pt={sectionVerticalPadding}
            pb={sectionVerticalPaddingExtra}
            px={landingPaddingX}
            {...contentWidthStyles}
          >
            <ImageWithText
              headingText="Detect distorted thoughts"
              description={
                <>
                  Learn to spot harmful patterns like{' '}
                  <strong>thinking traps</strong> and{' '}
                  <strong>core beliefs</strong>. Practice challenging unhelpful
                  thoughts and finding new perspectives.
                </>
              }
              additionalContent={
                <>
                  <Link
                    as={RouterLink}
                    to="/learn/thinking-traps"
                    color="action.secondary"
                    textStyle="popDuck"
                  >
                    Learn more about thinking traps{' '}
                    <ChevronLeft
                      transform="rotate(180deg)"
                      boxSize="1rem"
                      aria-hidden
                    />
                  </Link>
                  <Link
                    as={RouterLink}
                    to="/learn/core-beliefs"
                    display="block"
                    mt="deci"
                    color="action.secondary"
                    textStyle="popDuck"
                  >
                    Learn more about core beliefs{' '}
                    <ChevronLeft
                      transform="rotate(180deg)"
                      boxSize="1rem"
                      aria-hidden
                    />
                  </Link>
                </>
              }
              image={{
                src: images.traps,
                alt: '',
              }}
            />
          </Box>

          <Wave {...waveStyles} color="brand.bg.10" aria-hidden />

          <Box
            bg="brand.bg.10"
            px={landingPaddingX}
            py={sectionVerticalPadding}
            textAlign="center"
          >
            <Box {...heroWidthStyles}>
              <PullQuote {...userQuotes[1]} />
            </Box>
          </Box>

          <Wave {...waveStyles} color="white" aria-hidden />

          <Box
            pt={sectionVerticalPaddingExtra}
            pb={sectionVerticalPadding}
            px={landingPaddingX}
            {...contentWidthStyles}
          >
            <ImageWithText
              headingText="Training for your brain"
              description={
                <>
                  Using Unstuck is like{' '}
                  <strong>taking your brain to the gym!</strong> Practice over
                  time improves your mental resilience and trains you to catch
                  and interrupt negative thought spirals.
                </>
              }
              image={{
                src: images.exercise,
                alt: '',
              }}
              isImageFirst
            />
          </Box>

          <Box
            pt={sectionVerticalPadding}
            pb={sectionVerticalPaddingExtra}
            px={landingPaddingX}
            layerStyle="blueGradient"
          >
            <Box {...contentWidthStyles}>
              <ImageWithText
                headingText="Stay on track with goals"
                description={
                  <>
                    Set goals for how often you want to journal and get{' '}
                    <strong>smart reminders</strong>.
                  </>
                }
                additionalContent={
                  <Box textAlign="center">
                    <Image
                      src={images.notification}
                      display="inline-block"
                      maxWidth="380px"
                      width="100%"
                      alt=""
                    />
                  </Box>
                }
                image={{
                  src: goalImage,
                  alt: '',
                }}
              />
            </Box>
          </Box>

          <Wave {...waveStyles} color="white" aria-hidden />

          <Box
            pt={sectionVerticalPadding}
            pb={sectionVerticalPaddingExtra}
            px={landingPaddingX}
          >
            <Box {...contentWidthStyles}>
              <Heading
                color="blue.700"
                textStyle={{ base: 'popEmu', lg: 'popOstrich' }}
                mb="giga"
                textAlign="center"
              >
                Take control of your mental health with Unstuck
              </Heading>
              <ValuePropCards />
            </Box>
          </Box>

          <Box pt={sectionVerticalPadding} pb={sectionVerticalPaddingExtra}>
            <Box textAlign="center">
              <Heading
                color="blue.700"
                textStyle={{ base: 'popEmu', lg: 'popOstrich' }}
                mb={{ base: 'deci', md: 'giga' }}
              >
                What people are saying about Unstuck
              </Heading>
              <QuotesCarousel quotes={userQuotes.slice(2)} />
            </Box>
          </Box>

          <Box
            textAlign={{ base: 'center', lg: 'left' }}
            py={sectionVerticalPadding}
            px={landingPaddingX}
            layerStyle="blueGradient"
          >
            <Box {...contentWidthStyles}>
              <Box
                display="flex"
                flexDirection={{ base: 'column', lg: 'row-reverse' }}
                justifyContent="space-between"
                gap={{ base: 'regular', md: 'hecto' }}
              >
                <AvatarGroup
                  display="inline-flex"
                  justifyContent={{ base: 'center', lg: 'end' }}
                  flex={1}
                >
                  {[{ src: maria }, { src: calvin }, { src: stephanie }].map(
                    (props, index) => (
                      <Avatar key={index} {...props} boxSize="7.5rem" />
                    ),
                  )}
                </AvatarGroup>
                <Box flex={1}>
                  <Heading
                    textStyle={{ base: 'popEmu', md: 'popOstrich' }}
                    color="blue.700"
                    mb="deci"
                  >
                    The team behind Unstuck
                  </Heading>
                  <Text textStyle="goose">
                    Hi, we’re <strong>Maria</strong>, <strong>Calvin</strong>,
                    and <strong>Stephanie</strong> — a small team driven by a
                    passion to make mental health care more accessible.
                  </Text>
                  <Box mt="regular">
                    <Link
                      as={RouterLink}
                      to="about"
                      textStyle="popDuck"
                      color="blue.500"
                    >
                      Read more{' '}
                      <ChevronLeft
                        transform="rotate(180deg)"
                        boxSize="1rem"
                        aria-hidden
                      />
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Wave {...waveStyles} color="white" aria-hidden />

          <Box py={sectionVerticalPadding} px={landingPaddingX}>
            <Box {...heroWidthStyles}>
              <Box textAlign="center" color="brand.2">
                <Heading textStyle={{ base: 'popEmu', lg: 'popOstrich' }}>
                  Overcome anxiety with Unstuck
                </Heading>
                <Text textStyle={{ base: 'goose', lg: 'swan' }} mt="deci">
                  Start your free trial right here in the browser or download on
                  the App Store and Google Play.
                </Text>
              </Box>
              <Box mt="8vh" {...stackWidthStyles}>
                <AppStoreLinks variant="gradient" />
              </Box>
            </Box>
          </Box>

          <Footer />
        </>
      )}
    </Box>
  );
};

export default Landing;
